import { useParams } from '@reach/router';
import { isBefore } from 'date-fns';
import { graphql } from 'gatsby';
import React, { FC } from 'react';

import { useGetPlayerRewardsPageLink } from '@/bits/links/useLink';
import {
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  Date as DateComponent,
  InlineIconButton,
  Value,
} from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  EditIcon,
  NextIcon,
  PaperPlaneIcon,
  PlusIcon,
  PreviousIcon,
  RefreshIcon,
  TrashIcon,
} from '@/components/icons';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@/components/Tabs';
import { TranslateFn, useTranslate } from '@/contexts';
import {
  RewardPlayerAssignForm,
  RewardRemoveForm,
  RewardRemovePlayersForm,
  RewardResolveForm,
  RewardRetryForm,
} from '@/forms';
import { PlayerRewardStatus } from '@/globalTypes';
import { RewardDetailQuery } from './__generated__/useRewardDetail';
import useRewardDetail from './useRewardDetail';

export const Fragment = graphql`
  fragment SanityRewardDetailBlock on SanityRewardDetailBlock {
    title {
      ...SanityLocaleString
    }
    name {
      ...SanityLocaleString
    }
    comments {
      ...SanityLocaleString
    }
    updatedAt {
      ...SanityLocaleString
    }
    playerId {
      ...SanityLocaleString
    }
    reason {
      ...SanityLocaleString
    }
    agent {
      ...SanityLocaleString
    }
    resolvePlayerReward {
      ...SanityLocaleString
    }
    rewardType {
      ...SanityLocaleString
    }
    createdBy {
      ...SanityLocaleString
    }
    createdAt {
      ...SanityLocaleString
    }
    status {
      ...SanityLocaleString
    }
    gameName {
      ...SanityLocaleString
    }
    paylines {
      ...SanityLocaleString
    }
    amount {
      ...SanityLocaleString
    }
    numberSpins {
      ...SanityLocaleString
    }
    currency {
      ...SanityLocaleString
    }
    value {
      ...SanityLocaleString
    }
    startDate {
      ...SanityLocaleString
    }
    endDate {
      ...SanityLocaleString
    }
    cardTitle {
      ...SanityLocaleString
    }
    rewardPlayers {
      ...SanityLocaleString
    }
    infoTab {
      ...SanityLocaleString
    }
    playersTab {
      ...SanityLocaleString
    }
    resolve {
      ...SanityLocaleString
    }
    actions {
      ...SanityLocaleString
    }
    remove {
      ...SanityLocaleString
    }
    removePlayerReward {
      ...SanityLocaleString
    }
    playerRemoved {
      ...SanityLocaleString
    }
    noAction {
      ...SanityLocaleString
    }
    campaignId {
      ...SanityLocaleString
    }
    expiresIn {
      ...SanityLocaleString
    }
    maxPayout {
      ...SanityLocaleString
    }
    wageringRequirementFactor {
      ...SanityLocaleString
    }
  }
`;

type Reward = NonNullable<RewardDetailQuery['viewer']['reward']>;

type RewardType = NonNullable<Reward>['rewardType'];

const getRewardGameName = (rewardType?: RewardType) => {
  if (rewardType?.__typename === 'FreeSpinReward') {
    const game =
      rewardType?.gameDescriptor &&
      'json' in rewardType?.gameDescriptor &&
      rewardType.gameDescriptor.json;

    return game?.name;
  }
  return undefined;
};

const getRewardBetLevel = (rewardType?: RewardType) => {
  if (rewardType?.__typename === 'FreeSpinReward') {
    return rewardType.betLevel;
  }
  return undefined;
};

const getRewardAmount = (rewardType?: RewardType) => {
  if (rewardType?.__typename === 'FreeSpinReward') {
    return rewardType.freeSpinsAmount;
  }

  if (rewardType?.__typename === 'MoneyDropReward') {
    return rewardType.moneyDropAmount;
  }

  if (rewardType?.__typename === 'BonusMoneyDropReward') {
    return rewardType.bonusMoneyDropAmount;
  }

  return undefined;
};

const getMaxPayout = (rewardType?: RewardType) => {
  if (rewardType?.__typename === 'BonusMoneyDropReward') {
    return rewardType.maxPayout;
  }
  return undefined;
};

const getWageringFactorPayout = (rewardType?: RewardType) => {
  if (rewardType?.__typename === 'BonusMoneyDropReward') {
    return rewardType.wageringRequirementFactor;
  }
  return undefined;
};

const getRewardValue = (rewardType?: RewardType) => {
  if (rewardType?.__typename === 'FreeSpinReward') {
    return rewardType.value;
  }
  return undefined;
};

const isRewardExpired = (date: string | null | undefined) => {
  if (!date) return true;

  return isBefore(new Date(date), new Date());
};

const getRewardAction = (
  row: NonNullable<NonNullable<Reward['players']['edges']>[number]>['node'],
  t: TranslateFn,
  rewardId: string,
  block: Queries.SanityRewardDetailBlockFragment,
) => {
  if (
    row.status !== 'Removed' &&
    row.status !== 'FailedProcessing' &&
    row.status !== 'Credited' &&
    row.status !== 'Expired'
  ) {
    return (
      <div className="flex items-center">
        <ControlledModal
          content={
            <Card
              title={t(block.removePlayerReward)}
              size="lg"
              options={
                <CardOptions>
                  <CardCloseButton />
                </CardOptions>
              }
            >
              <RewardRemoveForm
                playerId={row.playerId}
                rewardId={rewardId}
                assignationId={row.assignationId}
              />
            </Card>
          }
        >
          <InlineIconButton>
            <TrashIcon />
          </InlineIconButton>
        </ControlledModal>
      </div>
    );
  }
  if (row.status === 'FailedProcessing') {
    return (
      <div className="flex items-center">
        <ControlledModal
          content={
            <Card
              title={t(block.resolvePlayerReward)}
              size="lg"
              options={
                <CardOptions>
                  <CardCloseButton />
                </CardOptions>
              }
            >
              <RewardResolveForm
                playerId={row.playerId}
                reason={row.reason}
                rewardId={rewardId}
                assignationId={row.assignationId}
              />
            </Card>
          }
        >
          <InlineIconButton>
            <EditIcon />
          </InlineIconButton>
        </ControlledModal>
      </div>
    );
  }
  return <>{t(block.noAction)}</>;
};

const RewardDetailBlock: FC<{
  block: Queries.SanityRewardDetailBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const getPlayerRewardsLink = useGetPlayerRewardsPageLink();
  const params = useParams();
  const {
    reward,
    rewardPlayers,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = useRewardDetail(params.rewardId);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'rewardDetail',
    data: rewardPlayers,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.dateTimeValue({
        field: 'updatedAt',
        title: t(block.updatedAt),
        fromFilterField: 'updatedAtFrom',
        toFilterField: 'updatedAtTo',
      }),
      s.stringValue({
        field: 'playerId',
        title: t(block.playerId),
        linkTo: ({ row }) => getPlayerRewardsLink(row.id) || '',
      }),
      s.enumValue({
        field: 'status',
        title: t(block.status),
        filterField: 'rewardStatus',
        e: PlayerRewardStatus,
      }),
      s.stringValue({
        field: 'agent',
        title: t(block.agent),
        getValue: ({ row }) => row.agent?.firstName + ' ' + row.agent?.lastName,
      }),
      s.stringValue({
        field: 'reason',
        title: t(block.reason),
        getValue: ({ row }) =>
          row.status === 'FailedProcessing' ? '-' : row.reason || '-',
      }),
      s.stringValue({
        field: '',
        title: t(block.actions),
        wrap: ({ row }) => getRewardAction(row, t, params.rewardId, block),
      }),
    ],
  });

  const tabIndex =
    Number(new URLSearchParams(window.location.search).get('tabIndex')) || 0;

  return (
    <Card size="lg" title={t(block.cardTitle)} showOptionsAtBottom>
      <CardBody>
        <Tabs defaultIndex={tabIndex}>
          <TabList>
            <Tab>{t(block.infoTab)}</Tab>
            <Tab>{t(block.playersTab)}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
                <Value
                  fetching={fetching}
                  title={t(block.name)}
                  value={reward?.name}
                />
                <Value
                  fetching={fetching}
                  title={t(block.rewardType)}
                  value={reward?.rewardType?.name}
                />
                <Value
                  fetching={fetching}
                  title={t(block.campaignId)}
                  value={reward?.campaignId}
                />
                <Value
                  fetching={fetching}
                  title={t(block.createdBy)}
                  value={reward?.createdBy?.firstName}
                />
                <Value title={t(block.createdAt)} fetching={fetching}>
                  <DateComponent copyable date={reward?.createdAt} />
                </Value>
                <Value title={t(block.startDate)} fetching={fetching}>
                  <DateComponent copyable date={reward?.startsAt} />
                </Value>
                <Value title={t(block.endDate)} fetching={fetching}>
                  <DateComponent copyable date={reward?.endsAt} />
                </Value>
                <Value
                  fetching={fetching}
                  title={t(block.status)}
                  value={reward?.status}
                />
                <Value
                  fetching={fetching}
                  title={
                    reward?.rewardType.name === 'FreeSpins'
                      ? t(block.numberSpins)
                      : t(block.amount)
                  }
                  value={getRewardAmount(reward?.rewardType)}
                />
                <Value
                  fetching={fetching}
                  title={t(block.currency)}
                  value={reward?.rewardType.currency}
                />
                {reward?.rewardType.name === 'FreeSpins' ? (
                  <>
                    <Value
                      fetching={fetching}
                      title={t(block.value)}
                      value={getRewardValue(reward?.rewardType)}
                    />
                    <Value
                      fetching={fetching}
                      title={t(block.paylines)}
                      value={getRewardBetLevel(reward?.rewardType)}
                    />
                    <Value
                      fetching={fetching}
                      title={t(block.gameName)}
                      value={getRewardGameName(reward?.rewardType)}
                    />
                  </>
                ) : null}
                {reward?.rewardType.name === 'BonusMoneyDrop' ? (
                  <>
                    <Value
                      fetching={fetching}
                      title={t(block.maxPayout)}
                      value={getMaxPayout(reward?.rewardType)}
                    />
                    <Value
                      fetching={fetching}
                      title={t(block.wageringRequirementFactor)}
                      value={getWageringFactorPayout(reward?.rewardType)}
                    />
                  </>
                ) : null}
                <Value
                  fetching={fetching}
                  title={t(block.comments)}
                  value={reward?.comments}
                />
              </div>
            </TabPanel>
            <TabPanel>
              <Card
                size="lg"
                title={t(block.rewardPlayers)}
                showOptionsAtBottom
                options={
                  <CardOptions>
                    <CardOptionsButton
                      disabled={!previousPage}
                      onClick={() => previousPage && previousPage()}
                    >
                      <PreviousIcon />
                    </CardOptionsButton>
                    <CardOptionsButton
                      disabled={!nextPage}
                      onClick={() => nextPage && nextPage()}
                    >
                      <NextIcon />
                    </CardOptionsButton>
                    <GlobalCardOptions />
                    <CardOptionsButton
                      onClick={() => refresh({ requestPolicy: 'network-only' })}
                    >
                      <RefreshIcon />
                    </CardOptionsButton>
                    <ControlledModal content={<RewardRetryForm />}>
                      <CardOptionsButton
                        disabled={isRewardExpired(reward?.endsAt)}
                      >
                        <PaperPlaneIcon />
                      </CardOptionsButton>
                    </ControlledModal>
                    <ControlledModal
                      content={
                        <RewardRemovePlayersForm rewardId={params.rewardId} />
                      }
                    >
                      <CardOptionsButton>
                        <TrashIcon />
                      </CardOptionsButton>
                    </ControlledModal>
                    <ControlledModal
                      content={<RewardPlayerAssignForm reward={reward} />}
                    >
                      <CardOptionsButton
                        disabled={isRewardExpired(reward?.endsAt)}
                      >
                        <PlusIcon />
                      </CardOptionsButton>
                    </ControlledModal>
                  </CardOptions>
                }
              >
                <CardBody>
                  <DataGrid />
                </CardBody>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CardBody>
    </Card>
  );
};

export default RewardDetailBlock;
