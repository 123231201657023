import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import React, { FC, useMemo } from 'react';

import {
  useGetGlobalPlayerOverviewPageLink,
  useGetPlayerOverviewPageLink,
} from '@/bits/links/useLink';
import {
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  Date as DateComponent,
  InlineIconButton,
  Value,
} from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  InformationIcon,
  NextIcon,
  PreviousIcon,
  RefreshIcon,
} from '@/components/icons';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@/components/Tabs';
import { useTranslate } from '@/contexts';
import { renderRewardDetails } from './renderRewardDetails';
import useChallengeDetail from './useChallengeDetail';

export const Fragment = graphql`
  fragment SanityChallengeDetailBlock on SanityChallengeDetailBlock {
    challengeId {
      ...SanityLocaleString
    }
    challengeUuid {
      ...SanityLocaleString
    }
    name {
      ...SanityLocaleString
    }
    status {
      ...SanityLocaleString
    }
    startDate {
      ...SanityLocaleString
    }
    endDate {
      ...SanityLocaleString
    }
    countries {
      ...SanityLocaleString
    }
    brands {
      ...SanityLocaleString
    }
    eligibleCriteria {
      ...SanityLocaleString
    }
    challengeActions {
      ...SanityLocaleString
    }
    challengeRewards {
      ...SanityLocaleString
    }
    infoTab {
      ...SanityLocaleString
    }
    playersTab {
      ...SanityLocaleString
    }
    cardTitle {
      ...SanityLocaleString
    }
    numberOfPlayers {
      ...SanityLocaleString
    }
    updatedAt {
      ...SanityLocaleString
    }
    globalId {
      ...SanityLocaleString
    }
    agent {
      ...SanityLocaleString
    }
    reason {
      ...SanityLocaleString
    }
    actions {
      ...SanityLocaleString
    }
    statusCompleted {
      ...SanityLocaleString
    }
    statusActive {
      ...SanityLocaleString
    }
  }
`;

const ChallengeDetailBlock: FC<{
  block: Queries.SanityChallengeDetailBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const params = useParams();

  const {
    desc,
    setOrderBy,
    challenge,
    fetching,
    defaultFilter,
    updateFilter,
    players,
    nextPage,
    previousPage,
    refresh,
  } = useChallengeDetail(params.challengeId);

  const getGlobalPlayerOverviewPageLink = useGetGlobalPlayerOverviewPageLink();
  const getPlayerOverviewPageLink = useGetPlayerOverviewPageLink();

  const { DataGrid } = useDataGrid({
    name: 'challengeDetail',
    data: players,
    fetching,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.stringValue({
        field: 'globalId',
        title: t(block.globalId),
        getValue: ({ row }) => row.playerUUID,
        linkTo: ({ row }) => {
          const typeOfChallenge = challenge?.challengeActions[0];
          const reDirectLink =
            typeOfChallenge === 'Deposit'
              ? getPlayerOverviewPageLink(row.playerId)
              : getGlobalPlayerOverviewPageLink(row.globalId);
          return reDirectLink ? reDirectLink : '';
        },
      }),
      s.stringValue({
        field: 'status',
        title: t(block.status),
        getValue: ({ row }) => {
          if (row.challengeCriteriaTotal === row.challengeCriteriaCompleted) {
            return t(block.statusCompleted);
          }
          return t(block.statusActive);
        },
      }),
    ],
  });

  const tabIndex =
    Number(new URLSearchParams(window.location.search).get('tabIndex')) || 0;

  const brands = String(challenge?.brands);
  const countries = String(challenge?.countries);
  const challengeRewards = challenge?.challengeRewards ?? [];

  const challengeRewardsNames = useMemo(
    () => challengeRewards?.map((reward) => reward.challengeRewardType.name),
    [challengeRewards],
  );

  return (
    <Card size="lg" title={t(block.cardTitle)} showOptionsAtBottom>
      <CardBody>
        <Tabs defaultIndex={tabIndex}>
          <TabList>
            <Tab>{t(block.infoTab)}</Tab>
            <Tab>{t(block.playersTab)}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
                <Value
                  fetching={fetching}
                  title={t(block.challengeUuid)}
                  value={challenge?.challengeUUID}
                />
                <Value
                  fetching={fetching}
                  title={t(block.name)}
                  value={challenge?.name}
                />
                <Value
                  fetching={fetching}
                  title={t(block.numberOfPlayers)}
                  value={challenge?.numberOfPlayers}
                />
                <Value
                  fetching={fetching}
                  title={t(block.status)}
                  value={challenge?.status}
                />
                <Value
                  fetching={fetching}
                  title={t(block.brands)}
                  value={brands}
                />
                <Value
                  fetching={fetching}
                  title={t(block.countries)}
                  value={countries}
                />
                <Value
                  fetching={fetching}
                  title={t(block.startDate)}
                  value={challenge?.startDate}
                >
                  <DateComponent date={challenge?.startDate} />
                </Value>
                <Value
                  fetching={fetching}
                  title={t(block.endDate)}
                  value={challenge?.endDate}
                >
                  <DateComponent date={challenge?.endDate} />
                </Value>
                <Value fetching={fetching} title="Rewards">
                  {challengeRewardsNames?.join(', ')}
                  <ControlledModal
                    content={
                      <Card
                        size="lg"
                        title="Rewards"
                        options={
                          <CardOptions>
                            <CardCloseButton />
                          </CardOptions>
                        }
                      >
                        {challengeRewards.map((reward, index) => (
                          <div className="p-6" key={index}>
                            {renderRewardDetails(reward)}
                          </div>
                        ))}
                      </Card>
                    }
                  >
                    <InlineIconButton>
                      <InformationIcon />
                    </InlineIconButton>
                  </ControlledModal>
                </Value>
              </div>
            </TabPanel>
            <TabPanel>
              <Card
                size="lg"
                title={t(block.playersTab)}
                showOptionsAtBottom
                options={
                  <CardOptions>
                    <CardOptionsButton
                      disabled={!previousPage}
                      onClick={() => previousPage && previousPage()}
                    >
                      <PreviousIcon />
                    </CardOptionsButton>
                    <CardOptionsButton
                      disabled={!nextPage}
                      onClick={() => nextPage && nextPage()}
                    >
                      <NextIcon />
                    </CardOptionsButton>
                    <CardOptionsButton
                      onClick={() => refresh({ requestPolicy: 'network-only' })}
                    >
                      <RefreshIcon />
                    </CardOptionsButton>
                  </CardOptions>
                }
              >
                <CardBody>
                  <DataGrid />
                </CardBody>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CardBody>
    </Card>
  );
};

export default ChallengeDetailBlock;
