import React from 'react';

import { Card, CardCloseButton, CardOptions, DetailList } from '@/components';
import formatMoney from '@/utils/formatter/formatMoney';
import {
  GlobalComplianceGlobalPlayerLimits,
  complianceLimitNames,
} from './components';

const LimitsDetails = ({
  limitName,
  activeFrom,
  euroThreshold,
  euroAmount,
  limitReached,
  latestChangeAt,
}: GlobalComplianceGlobalPlayerLimits) => {
  const items = [
    {
      label: 'Limit Name',
      value: limitName,
    },
    {
      label: 'Active From',
      value: activeFrom || '-',
    },
    {
      label: 'Euro Threshold',
      value: formatMoney(euroThreshold, 'EUR'),
    },
    {
      label: 'Euro Amount',
      value: formatMoney(euroAmount, 'EUR'),
    },
    {
      label: 'Limit Reached',
      value: limitReached ? 'Yes' : 'No',
    },
    {
      label: 'Latest Change At',
      value: latestChangeAt,
    },
  ];

  return (
    <Card
      size="lg"
      title={complianceLimitNames[limitName]}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <DetailList items={items} />
    </Card>
  );
};

export default LimitsDetails;
