import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { gql, useQuery } from 'urql';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
  Value,
} from '@/components';
import { InformationIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import {
  GlobalPlayerComplianceGlobalLimitsQuery,
  GlobalPlayerComplianceGlobalLimitsQueryVariables,
} from './__generated__/components';
import LimitsDetails from './LimitsDetails';

export const Fragment = graphql`
  fragment SanityGlobalPlayerComplianceLimitsBlock on SanityGlobalPlayerComplianceLimitsBlock {
    title {
      ...SanityLocaleString
    }
  }
`;

const query = gql`
  query GlobalPlayerComplianceGlobalLimits($playerGlobalId: ID!) {
    playerGlobal(playerGlobalId: $playerGlobalId) {
      complianceGlobalPlayerLimits {
        limitName
        activeFrom
        euroThreshold
        euroAmount
        limitReached
        latestChangeAt
      }
    }
  }
`;

export const complianceLimitNames: { [key: string]: string } = {
  SOW_180: 'Source of Wealth - 180 days',
  KYC_180: 'KYC - 180 days',
  CRA_DEPOSIT_90: 'CRA Deposit - 90 days',
  RG_CRA_DEPOSIT_30_MEDIUM: 'RG CRA Deposit - 30 days(Medium)',
  RG_CRA_DEPOSIT_30_HIGH: 'RG CRA Deposit - 30 days(High)',
  RG_CRA_DEPOSIT_30_VERY_HIGH: 'RG CRA Deposit - 30 days(Very High)',
};

export type GlobalComplianceGlobalPlayerLimits =
  GlobalPlayerComplianceGlobalLimitsQuery['playerGlobal']['complianceGlobalPlayerLimits'][number];

const LimitsValue: FC<{
  props: GlobalComplianceGlobalPlayerLimits;
  fetching?: boolean;
}> = ({ props }) => {
  const { limitName } = props;

  return (
    <Value
      title={
        <div className="flex">
          <span>{complianceLimitNames[limitName]}</span>
          <ControlledModal content={<LimitsDetails {...props} />}>
            <InlineIconButton>
              <InformationIcon />
            </InlineIconButton>
          </ControlledModal>
        </div>
      }
      value={props.limitReached ? 'Yes' : 'No'}
    />
  );
};

const PlayerScreeningStatusesBlock: FC<{
  block: Queries.SanityPlayerScreeningStatusesBlockFragment;
}> = ({ block }) => {
  const { playerGlobalId } = useParams();
  const { t } = useTranslate();

  const [{ data, fetching }, refresh] = useQuery<
    GlobalPlayerComplianceGlobalLimitsQuery,
    GlobalPlayerComplianceGlobalLimitsQueryVariables
  >({
    query,
    variables: {
      playerGlobalId,
    },
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="grid gap-3 p-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          {data?.playerGlobal.complianceGlobalPlayerLimits.map(
            (limitsDetails) => (
              <LimitsValue
                key={limitsDetails.limitName}
                props={limitsDetails}
                fetching={fetching}
              />
            ),
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerScreeningStatusesBlock;
