type FormatAmountParams = {
  amount: number | null | undefined;
  locale: 'mt-MT';
  currency?: string;
  precision?: number; // number of decimals
};

const LOCALE = 'mt-MT';

/**
 * For non standardized amounts, we ignore the precision and allow larger
 * decimals in order to show specific values. We also have a fixed
 * position for the currency.
 */
const formatNonStandardizedAmount = ({
  locale,
  amount,
  currency,
}: FormatAmountParams) => {
  const formattedAmount = Intl.NumberFormat(locale, {
    maximumFractionDigits: 18,
  }).format(amount ?? 0);

  if (!currency) {
    return formattedAmount;
  }

  return `${formattedAmount} ${currency}`;
};

export default function formatMoney(
  amount?: number | null,
  currency?: string | null,
  fractionDigits: number | undefined = 2,
) {
  if (typeof amount !== 'number' || !isFinite(amount)) {
    return '-';
  }

  const isStandardizedCurrency = !currency?.includes(':');

  if (currency && !isStandardizedCurrency) {
    return formatNonStandardizedAmount({
      locale: LOCALE,
      currency: currency.split(':')[1],
      amount,
    });
  }

  return new Intl.NumberFormat(LOCALE, {
    style: currency ? 'currency' : 'decimal',
    currency: currency || undefined,
    currencyDisplay: 'code',
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  }).format(amount);
}
